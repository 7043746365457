<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio ticket</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Img modal, triggered with click on ticket attachment -->
                <Transition name="fade-transition">
                    <div v-show="showImgModal" class="attachment_modal">
                        <div class="customers_container">
                            <img :src="selectedImage" alt="Allegato ticket" />
                        </div>
                        <div @click="closeAttachmentModal()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <!-- Ticket -->
                <div class="richiesta">
                    <div v-if="ticket.tickets_subject" class="field">
                        <div class="title ticket_title">
                            {{ ticket.tickets_subject ? ticket.tickets_subject : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data apertura</div>
                        <div class="value">
                            {{ ticket.tickets_creation_date ? dateFormat(ticket.tickets_creation_date) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Progetto</div>
                        <div class="value">
                            {{ ticket.projects_name ? ticket.projects_name : "-" }}
                        </div>
                    </div>
                    <div class="field address_field" v-if="setAddress(ticket).length != 0">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            {{ setAddress(ticket) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ setShortCustomer(ticket.customers_company) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            <span v-if="ticket.customers_phone">
                                <a :href="`tel:${ticket.customers_phone}`" class="mobile_link">{{ ticket.customers_phone }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Categoria</div>
                        <div class="value">
                            {{ ticket.tickets_category_value ? ticket.tickets_category_value : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Priorità</div>
                        <div class="badge" :class="prioritaTicket(ticket.tickets_priority)">
                            {{ ticket.tickets_priority_value ? ticket.tickets_priority_value : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato</div>
                        <div class="badge" :class="statoTicket(ticket.tickets_status)">
                            {{ ticket.tickets_status_value ? ticket.tickets_status_value : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Creato da</div>
                        <div class="value">
                            {{ ticket.users_first_name && ticket.users_last_name ? `${ticket.users_first_name} ${ticket.users_last_name}` : "-" }}
                        </div>
                    </div>
                    <div v-if="ticket.tickets_attachments" class="field_allegati">
                        <div class="title">Allegati</div>
                        <div class="allegati_container">
                            <img
                                v-for="(image, index) in JSON.parse(ticket.tickets_attachments)"
                                :key="index"
                                :src="setImageUrl(image)"
                                alt=""
                                @click="openImgDetail(image)"
                            />
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ parseHtmlEnteties(ticket.tickets_message) }}
                        </div>
                    </div>
                </div>

                <div v-if="ticket.tickets_status != 5" class="nuovo_intervento">
                    <div class="intervento_button" @click="openNuovoIntervento">
                        Crea intervento
                    </div>
                </div>
                <div class="interventi_container">
                    <div class="interventi_label">Interventi</div>
                    <div v-if="loading" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="interventi.length == 0">
                            <div class="no_interventi_container">
                                <div class="no_interventi">
                                    Nessun intervento effettuato
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                v-for="intervento in interventi"
                                :key="intervento.tickets_reports_id"
                                class="intervento"
                                @click="openInterventoDetail(intervento)"
                            >
                                <div class="date">
                                    {{ dateFormat(intervento.tickets_reports_date) }},
                                    {{ intervento.tickets_reports_start_time + " - " + intervento.tickets_reports_end_time }}
                                </div>
                                <div class="badge" :class="statusIntervento(intervento.tickets_reports_stato_lavori_chiudi_ticket)">
                                    {{ intervento.tickets_reports_stato_lavori_value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonSpinner,
    modalController,
} from "@ionic/vue";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { checkField, dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";
import interventiAPI from "@/services/interventi";
import ticketsAPI from "@/services/tickets";

import ModalNuovoInterventoFromTicket from "@/custom/ModalNuovoInterventoFromTicket";

import InterventoDetail from "@/custom/InterventoDetail";

export default defineComponent({
    name: "TicketDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
        ticket_id: {
            type: String,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonSpinner,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loading = ref(false);
        const ticket = ref([]);
        const interventi = ref([]);

        /**
         * ! Get ticekt data
         * ! ONLY IF COMING FROM INTERVENTO DETAIL
         */
        /* async function loadTicketDetail(ticket_id) {
            try {
                ticket.value = await ticketsAPI.getTicketData(ticket_id);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei dettagli del ticket", "toast_danger");
            } finally {
                console.log(ticket.value);
            }
        }

        if (props.ticket_id && typeof (props.ticket_id != "undefined")) {
            loadTicketDetail(props.ticket_id);
        } else {
            ticket.value = { ...props.data };
        } */
        ticket.value = { ...props.data };

        /**
         * Get related interventi
         */
        const loadInterventi = async () => {
            loading.value = true;
            //console.log(ticket.value);
            try {
                interventi.value = await interventiAPI.getInterventi(ticket.value.tickets_id);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta degli interventi", "toast_danger");
            } finally {
                loading.value = false;
            }
        };

        /**
         * ! Open modal to create new intervento
         */
        async function openNuovoIntervento() {
            const modal = await modalController.create({
                component: ModalNuovoInterventoFromTicket,
                componentProps: {
                    data: ticket,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    interventi.value.unshift(detail.data);
                    openToast("Intervento salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Open modal to create new intervento
         */
        async function openInterventoDetail(intervento) {
            const modal = await modalController.create({
                component: InterventoDetail,
                componentProps: {
                    data: intervento,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail);
                //Se ho modificato un intervento (firmato) devo richiedere i dati
                if (detail.data && detail.data.intervento_modificato === true) {
                    loadInterventi();
                }
            });
            return modal.present();
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Print projects address
         */
        function setAddress(ticket) {
            const city = ticket.projects_city ? `${ticket.projects_city}, ` : "";
            const address = ticket.projects_address ? `${ticket.projects_address} ` : "";
            const province = ticket.projects_province ? `(${ticket.projects_province})` : "";
            return `${city}${address}${province}`;
        }

        /**
         * ! Limit customers to 35 charactes
         */
        function setShortCustomer(customer) {
            if (!customer) {
                return "-";
            } else {
                const trimmedString = customer.length > 35 ? customer.substring(0, 32) + "..." : customer;
                return trimmedString;
            }
        }

        function setImageUrl(img) {
            //console.log(img);
            if (img) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
            }
        }

        /**
         * Remove html tag from ticket description
         */
        function parseHtmlEnteties(str) {
            if (str) {
                const strippedString = str.replace(/(<([^>]+)>)/gi, "");
                return strippedString.replace(/&#([0-9]{1,3});/gi, function(match, numStr) {
                    const num = parseInt(numStr, 10); // read num as normal number
                    return String.fromCharCode(num);
                });
            } else {
                return "-";
            }
        }

        //Set correct background for ticket status
        const statoTicket = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_open";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_working";
                } else if (statusId == 3) {
                    //ticket attesa risposta
                    className = "badge_waiting_answer";
                } else if (statusId == 4) {
                    //ticket standby
                    className = "badge_standy";
                } else if (statusId == 5) {
                    //tickt chiusi
                    className = "badge_close";
                } else if (statusId == 5) {
                    //tickt cancellati
                    className = "badge_canceled";
                }
                return className;
            };
        });

        //Set correct background for ticket priority
        const prioritaTicket = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //ticket aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //ticket in lavorazione
                    className = "badge_medium";
                } else if (statusId == 3) {
                    //ticket attesa risposta
                    className = "badge_high";
                }
                return className;
            };
        });

        //Set correct background for intervento status
        const statusIntervento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == "1") {
                    //lavoro completato
                    className = "badge_low";
                } else {
                    //lavoro non completato
                    className = "badge_high";
                }
                return className;
            };
        });

        /**
         * Open fake modal to view selected ticket attachment
         */
        const selectedImage = ref(null);
        const showImgModal = ref(false);

        function openImgDetail(image) {
            showImgModal.value = true;
            selectedImage.value = `${process.env.VUE_APP_BASE_URL}/uploads/${image.path_local}`;
        }

        function closeAttachmentModal() {
            showImgModal.value = false;
        }

        onMounted(() => {
            loadInterventi();
        });

        return {
            id,
            loading,
            statoTicket,
            checkField,
            dateFormat,
            dateFormatWithMinutes,
            ticket,
            arrowBackOutline,
            closeModal,
            parseHtmlEnteties,
            prioritaTicket,
            setShortCustomer,
            openNuovoIntervento,
            interventi,
            statusIntervento,
            openInterventoDetail,
            setImageUrl,
            //Attachment modal
            showImgModal,
            selectedImage,
            openImgDetail,
            closeAttachmentModal,
            setAddress,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .address_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.richiesta .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

.richiesta .address_field .title {
    margin-bottom: 4px;
}
.field .title,
.field_allegati .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_allegati .title,
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_allegati .value,
.field_note .value {
    font-size: 14px;
}

.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge {
    font-size: 10px;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_close,
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
    font-weight: bold;
}
.badge_standy,
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    font-weight: bold;
}
.badge_working,
.badge_medium {
    background-color: rgb(255 237 213);
    color: rgb(249 115 22);
    font-weight: bold;
}
.badge_waiting_answer {
    background-color: rgb(237 233 254);
    color: rgb(139 92 246);
    font-weight: bold;
}
.badge_open {
    background-color: rgb(207 250 254);
    color: rgb(6 182 212);
    font-weight: bold;
}
.badge_canceled {
    background-color: rgb(243 244 246);
    color: rgb(31 41 55);
    font-weight: bold;
}

.nuovo_intervento {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
}
.intervento_button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

/* Elenco interventi */
.interventi_container {
    margin-top: 16px;
}
.interventi_container .interventi_label {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #475569;
}
.intervento {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.intervento .date {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.intervento .status {
    font-size: 14px;
}

.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
}

.no_interventi_container {
    width: 100%;
    padding: 16px;
}

.no_interventi {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}

/** Modal selected attachment */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.attachment_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 16px;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

ion-button {
    --color: #ffffff;
}
</style>
