
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButtons, IonButton, menuController, modalController } from "@ionic/vue";
import { refresh, menu } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { Calendar } from "v-calendar";
import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiTickets from "@/services/tickets";

import TicketDetail from "@/custom/TicketDetail.vue";

export default {
    name: "Tickets",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButtons,
        IonButton,
        Calendar,
    },
    setup() {
        const router = useRouter();

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const loading = ref(false);

        const tickets = ref([]);
        const openedTickets = ref([]);
        const closedTickets = ref([]);
        const freeTickets = ref([]);

        const showAssegnati = ref(true);
        const showLiberi = ref(false);

        const date = ref(new Date());
        const selectedDay = ref(new Date()); // Add state to store selected day

        const openedTicketsToShow = ref([]); //ticket to filter clicked tickets

        const setAttributes = computed(() => {
            return openedTickets.value.map((ticket) => ({
                key: `ticket.${ticket.tickets_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: ticket.tickets_assign_date,
                customData: ticket,
            }));
        });

        /**
         * Filter my tickets based on selected day
         */
        let selectedDate = null;
        const dayClicked = (day) => {
            selectedDate = day.id;
            selectedDay.value = day;
            const selectedTickets = day.attributes;

            if (selectedTickets) {
                //svuoto e popolo con ticket della giornata selezionata
                openedTicketsToShow.value = [];
                selectedTickets.forEach((element) => {
                    openedTicketsToShow.value.push(element.customData);
                });
            } else {
                openedTicketsToShow.value = [];
            }
            //openedTicketsToShow.value = [...selectedTickets];
        };

        /**
         * Open sidebar menu
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setActiveTickets(type) {
            if (type === "liberi") {
                showLiberi.value = true;
                showAssegnati.value = false;
            } else {
                showAssegnati.value = true;
                showLiberi.value = false;
            }
        }

        async function openDetail(ticket) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: TicketDetail,
                componentProps: {
                    data: ticket,
                },
            });
            modal.onDidDismiss().then((detail) => {
                /* if (detail.data != undefined) {
          richieste.value = [detail.data, ...richieste.value];
          openToast("Nuova richiesta inserita", "toast_success");
        } */
            });
            return modal.present();
        }

        /**
         * Load tickets assigned to me and closed
         */
        async function loadTickets() {
            loading.value = true;
            try {
                tickets.value = await apiTickets.getTickets(userID);
                openedTickets.value = tickets.value.filter((ticket) => ticket.tickets_status != 5);
                openedTicketsToShow.value = [...openedTickets.value];
                closedTickets.value = tickets.value.filter((ticket) => ticket.tickets_status == 5);

                freeTickets.value = await apiTickets.getFreeTickets();
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei tickets", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * Set correct background for data assegnazione if expired
         */
        const expiredTicket = computed(() => {
            return (scadenza) => {
                let className = "not_epixred";
                if (scadenza) {
                    const dataScadenza = moment(scadenza).valueOf();
                    const dataAttuale = moment().valueOf();

                    if (dataScadenza < dataAttuale) {
                        className = "expired_ticket";
                    } else {
                        className = "not_epixred";
                    }
                    return className;
                } else {
                    return className;
                }
            };
        });

        onMounted(() => {
            //GET TICKETS DATA
            loadTickets();
        });

        return {
            loading,
            expiredTicket,
            dateFormat,
            refresh,
            openDetail,
            //Nuovi campi
            loadTickets,
            openMenu,
            menu,
            tickets,
            openedTickets,
            closedTickets,
            freeTickets,
            showLiberi,
            showAssegnati,
            setActiveTickets,
            //Calendar
            date,
            selectedDay,
            setAttributes,
            dayClicked,
            //filter ticket based on calendr click
            openedTicketsToShow,
        };
    },
};
